<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="dialog = true"
                  v-if="$store.state.user_permissions.project_delete"
                >
                  Usuń
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="edit_project()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-card>
          <v-card-title>Edycja projektu</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>

                <v-text-field
                  v-model="user_data.name"
                  label="Nazwa"
                  color="primary"
                ></v-text-field>

                <v-textarea
                  v-model="user_data.description"
                  label="Opis"
                  color="primary"
                ></v-textarea>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="user_data.budget_netto"
                      label="Budżet netto"
                      suffix="zł"
                      :rules="$store.state.rules.not_null"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="user_data.budget_brutto"
                      label="Budżet brutto"
                      suffix="zł"
                      :rules="$store.state.rules.not_null"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-select
                  v-model="selected_guardian"
                  :items="workers"
                  item-text="name"
                  item-value="id"
                  label="Opiekun projektu"
                  :rules="$store.state.rules.not_null"
                ></v-select>

                <v-autocomplete
                  v-model="selected_workers"
                  :items="workers"
                  item-text="name"
                  item-value="id"
                  no-data-text="Brak wyników"
                  label="Przypisani pracownicy"
                  chips
                  multiple
                  :rules="$store.state.rules.not_null"
                ></v-autocomplete>

                <v-switch
                  v-model="user_data.done"
                  label="Projekt zakończony?"
                  inset
                ></v-switch>
               
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    
    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz usuąć projekt?"
      @close="dialog = false;"
      @confirm="delete_project()"
    />
    
  </div>
</template>

<script>
export default {
  data: () => ({
    id: 0,
    dialog: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista projektów',
        disabled: false,
        to: '/projekty',
      },
      {
        text: 'Projekt',
        disabled: false,
        to: '/projekt/1',
      },
      {
        text: 'Edytuj projekt',
        disabled: true,
        to: '/projekt-edytuj',
      }
    ],

    user_data: {},
    
    selected_workers: [],
    workers: []
  }),
  methods: {
    edit_project() {
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("name", this.user_data.name);
      formData.append("description", this.user_data.description);
      formData.append("guardian", this.selected_guardian);
      formData.append("budget_netto", this.user_data.budget_netto);
      formData.append("budget_brutto", this.user_data.budget_brutto);
      if(this.user_data.done)formData.append("done", 1);
      else formData.append("done", 0);
      for(const item in this.selected_workers){
        formData.append("assign["+item+"]", this.selected_workers[item]);
      }
      this.$axios({url: this.$store.state.api +'/project/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/projekt/'+this.id);
          this.$store.commit("snackbar", {
            text: "Wprowadzono zmiany",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
    delete_project(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/project/'+this.id, data: {}, method: 'DELETE' })
        .then(resp => {
          this.$router.push('/projekty');
          this.$store.commit("snackbar", {
            text: "Usunięto projekt",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    }
  },
  mounted(){
    
    this.id = this.$route.params.id;
    console.log(this.id);

    // Edytowanie linku
    this.breadcrumb[2].to = '/projekt/'+this.id;

    this.$store.commit('loader');
    let loader = 2;

    this.$axios({url: this.$store.state.api +'/project/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.user_data = resp.data;
        this.selected_guardian = resp.data.guardian;
        // for (const item in resp.data.users) {
        //   this.selected_workers.push(resp.data.users[item].user_id);
        // }
        this.selected_workers = resp.data.users;
        console.log(resp.data);
        // this.user_data = resp.data.user_assign;
      })
      .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })
    
    this.$axios({url: this.$store.state.api +'/auth/user', data: {}, method: 'GET' })
      .then(resp => {
        this.workers = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
</style>